<template>
  <div class="upcoming_booking_request card-container">
    <div style="padding-bottom: 15px !important">
      <h4 class="section-title">Upcoming Booking Requests</h4>
    </div>
    <div class="table-wrapper">
      <v-data-table
        :headers="headers"
        :items="upcomingBookings"
        :server-items-length="totalUpcomingBookings"
        @click:row="editItem"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5],
        }"
        @update:options="fetch"
        fluid
        :height="calculateTableHeight()"
      >
        <template v-slot:item.bookable="{ item }">
          <v-container fluid class="d-flex align-items-center">
            <div class="left-div">
              <user-avatar
                :avatar="getBookableImage(item)"
                size="30"
                class="mr-4"
              />
            </div>
            <div class="right-div ml-2">
              <div>{{ item.bookable.title }}</div>
            </div>
          </v-container>
        </template>
        <template v-slot:item.datetime_start="{ item }">
          <div class="first-line-text text-right">{{ item.date }}</div>
          <div class="second-line-text text-right">{{ item.time }}</div>
        </template>
      </v-data-table>
    </div>
    <div class="div__button_see_all">
      <v-btn class="button_see_all" @click="seeAll" text>
        see all <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import ControlsMixin from '@/utils/mixins/Controls'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import { get } from 'lodash'

export default {
  name: 'UpcomingBookingRequest',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Booking Request',
          value: 'bookable',
        },
        {
          text: 'Date/Time',
          align: 'right',
          value: 'datetime_start',
        },
      ],
      options: {
        sortBy: ['bookable'],
        sortDesc: [true],
      },
    }
  },

  created() {
    this.fetch(this.options)
  },

  components: {
    UserAvatar,
  },

  computed: {
    ...mapState({
      upcomingBookings: (state) => state.booking.upcomingBookings,
      totalUpcomingBookings: (state) => state.booking.totalUpcomingBookings,
    }),
  },

  methods: {
    ...mapActions({
      getUpcomingBooking: 'booking/getUpcomingBooking',
    }),

    ...mapMutations({
      clearBookings: 'booking/clearBookingList',
    }),

    filterList(filterValue) {
      this.clearBookings()
      this.fetch(null, filterValue)
    },

    calculateTableHeight() {
      // Calculate the height based on the number of items per page (5 in this case)
      // You may need to adjust this calculation based on your specific styling and layout
      const itemHeight = 65 // Average height of a row, adjust as per your design
      const paginationHeight = 36 // Height of pagination control
      const rowsPerPage = 5 // Number of rows per page
      return rowsPerPage * itemHeight + paginationHeight + 'px'
    },

    async fetch(options, page = 1) {
      if (this.loading) return
      let sortBy = 'bookable'
      if (options?.sortBy[0] === 'title') {
        sortBy = 'bookable'
      } else {
        sortBy = 'datetime_start'
      }

      let params = {
        page: options?.page || 1,
        sort: options.sortDesc[0] ? '-' + sortBy : sortBy,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalUpcomingBookings
            : options.itemsPerPage
          : 5,
      }

      this.loading = true
      await this.getUpcomingBooking(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'booking.details',
        params: { id: item.id },
      })
    },

    seeAll() {
      this.$router.push({
        name: 'bookings',
      })
    },

    getBookableImage(bookingItem) {
      return get(bookingItem, 'bookable.media.0', undefined)
    },
  },
}
</script>
<style lang="scss" scoped>
.upcoming_booking_request .title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.card-container {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important; /* Ensure the card container fills its parent */
}
.div__button_see_all {
  text-align: right;
}
.button_see_all {
  text-transform: uppercase !important;
  color: #1976d2 !important;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  bottom: 0; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
}

.first-line-text {
  align-content: right;
}
.second-line-text {
  font-size: 0.8em;
  align-items: right;
}

.v-data-table {
  height: 100% !important;
  flex: 1 !important; /* Allow the table to fill remaining space */
  overflow-y: auto !important; /* Enable vertical scrolling if necessary */
}
.table-wrapper {
  height: 100% !important;
  flex: 1 !important; /* Allow the table to fill remaining space */
  overflow-y: auto !important; /* Enable vertical scrolling if necessary */
}
</style>
